import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Observable } from 'rxjs';

@Component({
  selector: 'itc-formly-field-legacy-read',
  template: `
    <div class="field-row">
      <span class="key">{{ field?.props?.label }}</span>
      <span
        class="value"
        [innerHTML]="formatValue(formControl.value) | fillEmptyValue | safeHtml"
      >
      </span>
    </div>
  `,
  styles: [
    '.field-row {display: grid; grid-template-columns: 1fr 1fr; justify-content: space-between; max-width: 500px; margin-bottom: $s10; }',
    '.key {font-weight: bold;}',
    '.value {white-space: pre-line;}',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegacyReadFieldComponent extends FieldType<FieldTypeConfig> {
  formatValue(val: any) {
    if (val === null || val === undefined) {
      return null;
    }
    if (this.field.props?.renderDiff) {
      const valueToCheck = this.field.props?.legacyValue;
      if (val !== valueToCheck)
        return `<span style="color:rgb(23, 13, 103); font-weight: bold;">${val}</span> changed from <strong>${valueToCheck}</strong>`;
    }
    const options = this.field.props?.options;
    if (
      Object.keys(val).length > 0 &&
      this.field.key === 'capacity_planning.restrictions'
    ) {
      const capacityRestrictions = Object.keys(val).filter(function (x) {
        return val[x];
      });
      return capacityRestrictions?.join(', ');
    }

    if (typeof val === 'object' && val?.label) {
      return val.label;
    }

    if (typeof val === 'object' && val.length !== undefined && val.length > 0) {
      return val.join(', ');
    }

    if (this.field.props?.type && this.field.props?.type === 'phone') {
      let formattedPhoneNumber =
        '(' +
        val.substring(0, 3) +
        ') ' +
        val.substring(3, 6) +
        '-' +
        val.substring(6);
      return formattedPhoneNumber;
    }

    // TODO: handle the case where options is
    // an Observable and use the async pipe
    if (options && !(options instanceof Observable)) {
      const mapping = (options as any[]).find((opt) => opt.value === val);
      if (mapping) {
        return mapping.label;
      } else {
        return val;
      }
    }
    return val;
  }
}
